import React from 'react'
import ConsultingTemplate from 'components/templates/ConsultingTemplate/ConsultingTemplate'

export default function ConsultingIso27001Page() {
  const seoKeywords = ['SEO0', 'SEO1', 'SEO2']

  const productName = 'ISO 27001'

  const description =
    'Qualità è anche ISO 27000: gestione della sicurezza delle informazioni, diventa parte integrante del Codice Privacy (GDPR)<br> La famiglia delle norme ISO 27000 è dedicata agli standard per la sicurezza informatica. Si tratta nel dettaglio, di tutte le tecniche e le modalità operative che le aziende dovrebbero seguire per mettere in sicurezza i propri dati.<br> In una realtà sempre più telematica circa il 60% delle proprietà intellettuali di un’impresa è contenuto in archivi per lo più informatizzati.<br> Si tratta, ad oggi, di una delle certificazioni più importanti per la realtà aziendale, poichè le informazioni rappresentano un vero e proprio patrimonio la cui gestione diventa strategica per la tutela e lo sviluppo dell’impresa. Il Sistema di Gestione delle Informazioni protegge infatti: la riservatezza, l’integrità e la disponibilità dei dati dell’azienda.<br> Fa parte della famiglia ISO 27000, la norma ISO/IEC 27001 dedicata alla Sicurezza dei Sistemi Informativi (SGSI).<br> ING GROUP è partner di svariate aziende che hanno scelto di implementare e certificare un sistema di gestione conforme alla ISO 27001'

  return (
    <>
      <ConsultingTemplate seoKeywords={seoKeywords} productName={productName} description={description} />
    </>
  )
}
